import { useState } from "react";
import gmine from "../assets/images/gmine.jpg";
import verive from "../assets/images/verive.jpg";
import wellLog from "../assets/images/wellLog.jpg";
import wellLog2 from "../assets/images/wellLog2.jpg";
import TwModal from "../components/TwModal";
import LandingPage from "./LandingPage";
import { useNavigate } from "react-router-dom";

function Portfolio() {
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(false);
  let [{ mod_title, mod_body, submit }, setModalContent] = useState({ mod_title: "", mod_body: "", submit: undefined })

  function closeModal() {
    setIsOpen(false)
  }

  const openModal = (mod_title, mod_body, submit) => () => {
    setIsOpen(true)
    setModalContent({ mod_title, mod_body, submit })
  }
  const callouts = [
    {
      id: 'hackathon',
      name: 'Hackathon',
      description: 'DELFI Data Science by Schlumberger',
      imageSrc: wellLog2,
      imageAlt: 'delfi hackathon.',
      onClick: openModal(
        "DELFI Data Science Hackathon by Schlumberger",
        "Developing AI-based concepts (machine learning) and spatial analysis namely PRIVATE for automation systems in identification hydrocarbon prospect zone.",
        () => window.location.href = "http://hackuna-delfi.herokuapp.com/"
      ),
      // href: '#',
    },
    {
      id: 'gmine',
      name: 'GMine',
      description: 'Mineral exploration data integration and analysis',
      imageSrc: gmine,
      imageAlt: 'gmine - platform.',
      onClick: openModal(
        "GMine",
        "A platform in mineral exploration data integration and analysis.",
        () => window.location.href = "http://gmine.netlify.app/"
      ),
    },
    // {
    //   id: 'verive',
    //   name: 'Verive',
    //   description: 'TheNextDev Summit Telkomsel - Idea Competition',
    //   imageSrc: verive,
    //   imageAlt: 'verive - idea competition',
    //   onClick: openModal(
    //     "Verive",
    //     "A software to detection and analyze fake accounts in social media, this prototype was pitched in TheNextDev Summit Telkomsel.",
    //     () => window.location.href = "http://verive-validation.netlify.app/"
    //   ),
    // },
    // {
    //   id: 'machine-learning',
    //   name: 'Machine Learning',
    //   description: 'Rock Facies Classification',
    //   imageSrc: wellLog,
    //   imageAlt: 'well log classification',
    //   onClick: openModal(
    //     "Rock Facies Classification",
    //     "Classification of Rock Facies from Well Logs with Comparison Supervised Machine-Learning : An Innovative Method to Understand Complexities Behind Reservoir Characterization",
    //     () => navigate("/portfolio")
    //   )
    // },
  ]

  const body = (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-6 lg:max-w-none">
          <h2 className="text-2xl font-light text-gray-900">Check Out Some of My Works..</h2>
          <TwModal action={{ isOpen, closeModal, submit }} title={mod_title} body={mod_body} />
          <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {callouts.map((callout) => (
              <div key={callout.id} id={callout.name} className="group relative">
                <div className="filter grayscale relative h-80 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                  <img
                    src={callout.imageSrc}
                    alt={callout.imageAlt}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <h3 className="mt-6 text-base font-bold text-gray-900 cursor-pointer">
                  <a href={callout.href} onClick={callout.onClick} >
                    <span className="absolute inset-0" />
                    {callout.name}
                  </a>
                </h3>
                <p className="text-xs text-gray-600 mx-20">{callout.description}</p>

              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
  return (
    <>
      <LandingPage page="portfolio" body={body} />
    </>
  );
}

export default Portfolio;