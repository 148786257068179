import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TwModal from "../components/TwModal";
import LandingPage from "./LandingPage";
import pertagamant from "../assets/images/geoscience.jpg";
import fieldcamp from "../assets/images/backend.jpg";
import spectragama from "../assets/images/wellLog2.jpg";
import ibadurrahman from "../assets/images/webdev.jpg";

function Project() {
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(false);
  let [{ mod_title, mod_body, submit }, setModalContent] = useState({ mod_title: "", mod_body: "", submit: undefined })

  function closeModal() {
    setIsOpen(false)
  }

  const openModal = (mod_title, mod_body, submit) => () => {
    setIsOpen(true)
    setModalContent({ mod_title, mod_body, submit })
  }
  const callouts = [
    // {
    //   id: 'pertagamant',
    //   name: 'PertaGAMANT',
    //   description: 'Geoseismal - Full Stack Developer',
    //   imageSrc: pertagamant,
    //   imageAlt: 'pertagamant.',
    //   onClick: openModal(
    //     "PertaGAMANT v2.0",
    //     "The ambient noise processing software collaboration between Geoseismal UGM and Pertamina Geothermal Energy.",
    //     () => navigate("project")
    //   ),
    //   // href: '#',
    // },
    {
      id: 'spectragama',
      name: 'Spectragama',
      description: 'Geoseismal - Full Stack Developer',
      imageSrc: spectragama,
      imageAlt: 'spectragama.',
      onClick: openModal(
        "Spectragama v3.0",
        "The passive seismic processing software collaboration between Geoseismal UGM and Pertamina Upstream Innovation",
        () => navigate("/spectragama")
      ),
    },
    {
      id: 'ibadurrahman',
      name: 'LAZ & LNW Ibadurrahman',
      description: 'Freelance - Web Developer',
      imageSrc: ibadurrahman,
      imageAlt: 'ibadurrahman',
      onClick: openModal(
        "Official Website Ibadurrahman",
        "Design and develop an official website of zakah organization in Duri, Riau with the donation system and routinely article release.",
        () => window.location.href = "https://laz.ibadurrahman.org/"
      ),
    },
    {
      id: 'field_camp',
      name: 'Field Camp',
      description: 'Geophysics UGM - Web Developer',
      imageSrc: fieldcamp,
      imageAlt: 'field camp',
      onClick: openModal(
        "Field Camp 2021",
        "Cotribute to build a full web design as a report on the field project with various geophysics methods for geotechnical purposes.",
        () => window.location.href = "https://fieldcampugm.vercel.app/"
      )
    },
  ]

  const body = (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-6 lg:max-w-none">
          <h2 className="text-2xl font-light text-gray-900">More of my credentials..</h2>
          <TwModal action={{ isOpen, closeModal, submit }} title={mod_title} body={mod_body} />
          <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {callouts.map((callout) => (
              <div key={callout.id} id={callout.name} className="group relative">
                <div className="filter grayscale relative h-80 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                  <img
                    src={callout.imageSrc}
                    alt={callout.imageAlt}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <h3 className="mt-6 text-base font-bold text-gray-900 cursor-pointer">
                  <a href={callout.href} onClick={callout.onClick} >
                    <span className="absolute inset-0" />
                    {callout.name}
                  </a>
                </h3>
                <p className="text-xs text-gray-600 mx-20">{callout.description}</p>

              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
  return ( 
    <>
      <LandingPage page="project" body={body} />
    </>
   );
}

export default Project;