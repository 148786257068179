import LandingPage from "./LandingPage";
import { PaperClipIcon } from '@heroicons/react/20/solid'
import certificate_1 from '../assets/documents/DTS - DQLab Data Science.pdf'
import certificate_2 from '../assets/documents/DTS - IBM Machine Learning.pdf'
import certificate_3 from '../assets/documents/Sanbercode - Back End Python.pdf'
import certificate_4 from '../assets/documents/Sanbercode - Data Science.pdf'

function Profile() {
  const body = (
    <>
      <div className="overflow-hidden bg-transparent shadow sm:rounded-lg mx-auto max-w-6xl py-2">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-2xl font-light leading-6 text-gray-900">All About Me</h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Full name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Muhammad Fajrul Haqqi</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Job</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Full Stack Developer</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">fajrulhaqqi@gmail.com</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Skill</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"> Django REST Framework, React JS, Electron JS, Data Analyst, Machine Learning</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">About</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                An organized, creative, and hard worker. Have a deep interest with analyzing data in earth science. Experience with data science and web development in geoscience cases.
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Certificate</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <span className="ml-2 w-0 flex-1 truncate">PROA DTS Kominfo - DQLab Data Science.pdf</span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a href={certificate_1} className="font-medium text-indigo-600 hover:text-indigo-500">
                        View
                      </a>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <span className="ml-2 w-0 flex-1 truncate">FGA DTS Kominfo - IBM Machine Learning.pdf</span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a href={certificate_2} className="font-medium text-indigo-600 hover:text-indigo-500">
                        View
                      </a>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <span className="ml-2 w-0 flex-1 truncate">Sanbercode - Backend Microservices with Python.pdf</span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a href={certificate_3} className="font-medium text-indigo-600 hover:text-indigo-500">
                        View
                      </a>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <span className="ml-2 w-0 flex-1 truncate">Sanbercode - Data Science Basic & Advance.pdf</span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a href={certificate_4} className="font-medium text-indigo-600 hover:text-indigo-500">
                        View
                      </a>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
        <div class="container bg-white mx-auto w-full h-full">
          <h2 className="text-2xl font-light text-gray-900 py-4">Work Experience</h2>
          <div class="relative wrap overflow-hidden p-10 h-full">
            <div class="border-2-2 absolute border-gray-700 bg-gray-700 h-full border inset-x-2/4"></div>
            {/* <!-- right timeline --> */}
            <div class="mb-8 flex justify-between items-center w-full right-timeline">
              <div class="order-1 w-5/12"></div>
              <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                <h1 class="mx-auto font-semibold text-lg text-white">1</h1>
              </div>
              <div class="order-1 bg-gray-800 rounded-lg shadow-xl w-5/12 px-6 py-4">
                <h3 class="mb-3 font-bold text-white text-xl">Geoseismal</h3>
                <p class="text-xs text-white my-4">Contracted for 10 months as a software developer to develop Spectragama v.3.0, the passive seismic processing software.</p>
                <p class="text-xs text-white my-4">Contracted 7 months to develop Petragamant, the Ambient Noise Measurements (ANT) processing software.</p>
                <p class="text-xs text-white my-4">Both of these projects are a collaboration between Universitas Gadjah Mada and Pertamina in research and software development.</p>
              </div>
            </div>

            {/* <!-- left timeline --> */}
            <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div class="order-1 w-5/12"></div>
              <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                <h1 class="mx-auto text-white font-semibold text-lg">2</h1>
              </div>
              <div class="order-1 bg-primary rounded-lg shadow-xl w-5/12 px-6 py-4">
                <h3 class="mb-3 font-bold text-black text-xl">Sparveon</h3>
                <p class="text-xs text-black my-2">Worked as a backend developer in AI-based seismic processing software to be released. The responsibility to develop code in converting seismic fault images into an integrated format in other software.</p>
              </div>
            </div>

            {/* <!-- right timeline --> */}
            <div class="mb-8 flex justify-between items-center w-full right-timeline">
              <div class="order-1 w-5/12"></div>
              <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                <h1 class="mx-auto font-semibold text-lg text-white">3</h1>
              </div>
              <div class="order-1 bg-gray-800 rounded-lg shadow-xl w-5/12 px-6 py-4">
                <h3 class="mb-3 font-bold text-white text-xl">Widya Analytics</h3>
                <p class="text-xs text-white my-2">Worked as a data scientist for an AI-based algorithm development service that focuses on Natural Language Processing (NLP).</p>
              </div>
            </div>

            {/* <!-- left timeline --> */}
            <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div class="order-1 w-5/12"></div>
              <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                <h1 class="mx-auto text-white font-semibold text-lg">4</h1>
              </div>
              <div class="order-1 bg-primary rounded-lg shadow-xl w-5/12 px-6 py-4">
                <h3 class="mb-3 font-bold text-black text-xl">BOB PT.BSP - Pertamina Hulu</h3>
                <p class="text-xs text-black my-2">Responsible for analyzing well log data and bareholes in Zamrud Field, Siak, Riau.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  return (
    <>
      <LandingPage page="profile" body={body} />
    </>
  );
}

export default Profile;