import { Fragment, useState } from "react";
import LandingPage from "../LandingPage";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";

export function MaterialCard(content, img_path, size) {
  return (
    <Card className={`w-1/2 hover:w-3/4 rounded-lg`}>
      <CardHeader color="black" className="relative">
        <img
          src={img_path}
          alt={`img: ${content}`}
          className="h-full w-full rounded-lg"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h6">{content}</Typography>
      </CardBody>
    </Card>
  );
}

function Spectragama() {
  const [open, setIsOpen] = useState(1);
  const handleOpen = (val) => {
    setIsOpen(open === val ? 0 : val);
  };

  const portfolio_list = [
    {
      id: 1,
      title: "Authentication Page",
      description: (
        <div className="flex flex-row">
          {MaterialCard("Sign Up", require("../../assets/images/spectragama/sign-up.gif"))}
          {MaterialCard("Sign In", require("../../assets/images/spectragama/sign-in.gif"))}
        </div>
      ),
    },
    {
      id: 2,
      title: "Project Management",
      description: (
        <div className="flex justify-center">
          {MaterialCard("Project Management", require("../../assets/images/spectragama/project-management.gif"))}
        </div>
      ),
    },
    {
      id: 3,
      title: "Spectrum Attribute",
      description: (
        <div className="flex justify-center">
          {MaterialCard("Visualization using echarts", require("../../assets/images/spectragama/spectrum-attr.gif"))}
        </div>
      ),
    },
    {
      id: 4,
      title: "Mapping",
      description: (
        <div className="flex justify-center">
          {MaterialCard("Mapping using leaflet", require("../../assets/images/spectragama/mapping.gif"))}
        </div>
      ),
    },
    {
      id: 5,
      title: "SEG-Y",
      description: (
        <div className="flex justify-center">
          {MaterialCard("Visualization using Plotly", require("../../assets/images/spectragama/segy.png"))}
        </div>
      ),
    },
    {
      id: 6,
      title: "Web-Based Architecture",
      description: (
        <div className="flex justify-center">
          {MaterialCard("Docker Container", require("../../assets/images/spectragama/docker.png"))}
        </div>
      ),
    },
  ];
  const body = (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5">
      <Fragment>
        {portfolio_list.map((item) => (
          <Accordion open={open === item.id}>
            <AccordionHeader onClick={() => handleOpen(item.id)}>
              {item.title}
            </AccordionHeader>
            <AccordionBody>
              <div className="h-96">{item.description}</div>
            </AccordionBody>
          </Accordion>
        ))}
      </Fragment>
    </div>
  );
  return (
    <>
      <LandingPage page="spectragama" body={body} />
    </>
  );
}

export default Spectragama;
