import { createBrowserRouter, Navigate } from "react-router-dom";
import LandingPage from './views/LandingPage';
import Portfolio from "./views/Portfolio";
import Project from "./views/Project";
import Profile from "./views/Profile";
import Spectragama from "./views/project/Spectragama";

const router = createBrowserRouter([
  {
    key: "navigate",
    path: "*",
    element: <Navigate to='/project' />
  },
  {
    key: "portfolio",
    path: "/portfolio",
    element: <Portfolio />
  },
  {
    key: "project",
    path: "/project",
    element: <Project />,
    // children: [
    //   {
    //     path: "/spectragama",
    //     element: <Spectragama />,
    //   },
    // ]
  },
  {
    key: "spectragama",
    path: "/spectragama",
    element: <Spectragama />
  },
  {
    key: "profile",
    path: "/profile",
    element: <Profile />
  },
])

export default router;